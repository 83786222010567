import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { ethers } from "ethers";
import dropList from "./whitelist.json";

let tree;

function hashToken(address, amount) {
  return Buffer.from(
    ethers.utils.solidityKeccak256(["address", "uint256"], [address, amount]).slice(2),
    "hex"
  );
}

function buildTree() {
  let merkleTree = new MerkleTree(
    Object.entries(dropList).map((drop) => hashToken(drop[0], drop[1])),
    keccak256,
    { sortPairs: true }
  );
  return merkleTree;
}

export function getAirdropAmount(wallet) {
  //alert(wallet.toLowerCase())
  return dropList[wallet.toLowerCase()] || 0;
}

export function getProof(wallet) {
  return tree.getHexProof(hashToken(wallet, getAirdropAmount(wallet)));
}

tree = buildTree()
//console.log("root HEX " + tree.getHexRoot());
//console.log("root STRING " + tree.toString());
//console.log("AirdropAmount : " + getAirdropAmount("0xd2ec10a55e08bc135077fb84e2522465f44e41da"));
//console.log("Proof : " + getProof("0xd2ec10a55e08bc135077fb84e2522465f44e41da"));