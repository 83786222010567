import Header2 from "./header/Header_2"
import "./BTD_Roadmap.css";
import React, { Component } from "react"; 
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
//import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
//import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
//import { id } from "ethers/lib/utils";

var w , h;
var isPlayRoadmap3D = false;
var renderer = new THREE.WebGLRenderer({alpha:true, antialias:true});
var composer;
var limw = 1100;
var ranN;

var camera;
var clock = new THREE.Clock();
var mixer1 , mixer2;
var obj1 , obj2 , currentOBJ;
var textOB;
var timeLoop = 0;
var font ,  text_geometry , textMesh;
var difftime,diffDay,diffHour,diffHour24,diffMinute,diffSecond,timeText;
var action0 , action1 , action2 , action3 , action4 , action5 , action6 , action7 ;
var startWidth;
var previousWidth;


var pointOB , pointNO ,pointEM1;
var turnHead_loopcount = 0;
var normal_loopcount = 0;
var book_loopcount = 0;
var pot_loopcount = 0;
var TV_loopcount = 0;
var NFT_loopcount = 0;
var picture_loopcount = 0;
var partner_loopcount = 0;
var isReadyToMint = true; 

var fng1 = "banner3D_18.glb";
var scene = new THREE.Scene();
var rm1,sl1,sl2; 
var ofy,ofy1,ofy2,ofy3,ofy4,ofblur,ofytotalcenter;
var deltaY;
var pushbackD;
var closeFounderPointer , closeVDOPointer , closePaperPointer , closeNFTPointer , closePartnerPointer;
var sceneOpening;
var playSound , isSoundPlayed = false;
var extraZ = 0;
var audio;
var previousPointer;
class Roadmap extends Component {
    componentDidMount() {
        ranN = parseInt(Math.random() * 100000) ;
        rm1 = document.getElementById("roadmap-container");
        sl1 = document.getElementById('sample-layer2-part1');
        sl2 = document.getElementById('sample-layer2-part2');
        w = document.getElementById('sample-layer2-part2-sub1').offsetWidth;
        h = document.getElementById('sample-layer2-part2-sub1').offsetHeight; // window.innerHeight;
        ofytotalcenter = (h - window.innerHeight)/2 ;
        //if(h<1000) h = 1000; 
        pushbackD = window.innerHeight;
        if(w<10000){
            //pushbackD = 1300;
        }
        var res1 = window.innerWidth/2000;
        if (res1 < 0.8) res1 = 0.8;
        renderer.setPixelRatio(1);
        //renderer.setPixelRatio(window.devicePixelRatio);
        //alert( window.innerWidth);

        camera  = new THREE.PerspectiveCamera(38,w/h,.01,20);
        if(window.innerHeight>window.innerWidth){
            camera.position.set(0,0.5,1);
        }else{
            camera.position.set(0,0.5,0);
        }
        
        renderer.setSize(w, h); 
        if(window.innerWidth > limw){
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
        }
        //renderer.setClearColor( 0x000000, 0 )
        //renderer.setClearColor(0x161722, 1);
        renderer.physicallyCorrectLights = true;
        renderer.toneMappingExposure = 1;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.outputEncoding = THREE.sRGBEncoding;
        if(window.innerWidth <= limw){
            //renderer.gammaOutput = true;
            //renderer.gammaFactor = 2;
        }
        this.mount.appendChild(renderer.domElement);
        const target = new THREE.WebGLRenderTarget( {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            encoding: THREE.sRGBEncoding
        } );
        //target.samples = 1;
        composer = new EffectComposer( renderer, target );
        //composer =  new EffectComposer(renderer);

        //let container, stats;
        //let generatedCubeRenderTarget, ldrCubeRenderTarget, hdrCubeRenderTarget, rgbmCubeRenderTarget;
        //let ldrCubeMap, rgbmCubeMap;
        let hdrCubeMap;
        const hdrUrls = [ 'px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr' ];
        hdrCubeMap = new HDRCubeTextureLoader()
                .setPath( 'hdr1/' )
                .setDataType( THREE.UnsignedByteType )
                .load( hdrUrls, function () {
                //hdrCubeRenderTarget = pmremGenerator.fromCubemap( hdrCubeMap );
                hdrCubeMap.magFilter = THREE.LinearFilter;
                hdrCubeMap.needsUpdate = true;
                hdrCubeMap.wrapS = hdrCubeMap.wrapT = THREE.ClampToEdgeWrapping;
                } );
        const loader = new GLTFLoader();
        

        loader.load(fng1,(gltf) => {
            obj1 = gltf.scene;
            mixer1 = new THREE.AnimationMixer( gltf.scene );

            
            action0 = mixer1.clipAction( gltf.animations[ 0 ] );
            action0.play();
            
            action1 = mixer1.clipAction( gltf.animations[ 1 ] );
            action1.play();
            
            action2 = mixer1.clipAction( gltf.animations[ 2 ] );
            action2.play();
            
            action3 = mixer1.clipAction( gltf.animations[3] );
            action3.play();
            /*
            action4 = mixer1.clipAction( gltf.animations.find(obj => obj.name == "MINT_1") );
            action4.clampWhenFinished = true;
            action4.setLoop(THREE.LoopOnce); */
            mixer1.addEventListener('finished', (e)=>{
                if(e.direction == -1){
                   // isReadyToMint = false;
                }else{
                   // isReadyToMint = true;
                }
            });
            
            /*mixer1.dispatchEvent( {
                type: 'finished', action: action4, direction: -1
            },()=>{alert('ddddddd')} );*/
            
            obj1.traverse((node)=>{
                if (node.isMesh) {
                    node.castShadow = false;
                    node.receiveShadow = false;
                    node.material.envMap = hdrCubeMap;
                }
                if(node.name == 'Floor'){
                    node.castShadow = false;
                    node.receiveShadow = true;
                }
                if(node.name == 'L1'){
                    node.castShadow = true;
                    node.receiveShadow = false;
                    node.shadow.mapSize.width = 256; // default
                    node.shadow.mapSize.height = 256; // default
                    node.shadow.camera.near = 0.5; // default
                    node.shadow.camera.far = 1; // default
                }
                if(node.name == 'BP1' || node.name == 'BP2' || node.name == "POT_1"){
                    node.castShadow = true;
                    node.receiveShadow = false;
                }
                if(node.name.startsWith('BALL')){
                    node.castShadow = true;
                    node.receiveShadow = false;
                }
                if(node.name.startsWith('TROPHY')){
                    node.castShadow = true;
                    node.receiveShadow = false;
                }
            });
           
            obj1.scale.set(1,1,1);
            scene.add( obj1 );
            obj1.name = 'obj1';
            pointOB = [ obj1.getObjectByName("pCube2_bookleather_0_1") ,
                obj1.getObjectByName("pCube2_bookleather_0") ,
                obj1.getObjectByName("POT_1") ,
                obj1.getObjectByName("TV") ,
                textMesh , 
                obj1.getObjectByName("PICTURE") ,
                obj1.getObjectByName("Plane002_1") ,
                obj1.getObjectByName("Plane002_2") ,
                obj1.getObjectByName("Plane002_3") ,
                obj1.getObjectByName("Plane002_4") ,
                obj1.getObjectByName("Plane002_5") ,
                obj1.getObjectByName("magic18"),
                obj1.getObjectByName("BALL_B1_1"),
                obj1.getObjectByName("BALL_B3_1") ,
                obj1.getObjectByName("BALL_B2_1") ,
                obj1.getObjectByName("TROPHY_2") ];
            pointNO = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
            pointEM1 = [0.001,0.001,0.001,0.001,0.001,0.001,0.001,1,1,1,1,10,100,100,100,10];
            if(window.innerWidth < window.innerHeight){
                obj1.getObjectByName("CANDLE1").position.y = obj1.getObjectByName("CANDLE1").position.y + .3;
                obj1.getObjectByName("CANDLE2").position.y = obj1.getObjectByName("CANDLE2").position.y + .3;
                obj1.getObjectByName("CANDLE3").position.y = obj1.getObjectByName("CANDLE3").position.y + .3;
            }
           
            timeLoop = 0;
            const fontLoader = new FontLoader();
            font = fontLoader.load(
                'Cinzel Bold_Regular.json' ,
                function ( fontL ) { 
                   /* setInterval(() => {
                        getCountDown();
                        updateText(fontL,timeText);  
                    }, 1000); */
                },
                function ( xhr ) {/*console.log( (xhr.loaded / xhr.total * 100) + '% loaded' ); */ },
                function ( err ) { console.log( 'An error happened' ); }
            );
            //updateText(font);
            
            
            //*************************************************
            //rotateToAngle(0);
            //Create a DirectionalLight and turn on shadows for the light
            //const helper = new THREE.CameraHelper( light.shadow.camera );
            //scene.add( helper );

            animate();
            
            //cancelAnimationFrame(myReq);
            //renderer.clear();
            document.getElementById('button_to_begin').style.display = 'block';
            document.getElementById('pgbar').style.display = 'none';
            document.getElementById('pgbar_label').style.display = 'none';
            },  
            function ( xhr ) {
                var percentLoaded = 100 * xhr.loaded / 30668800 ;
                document.getElementById('pgbar').value = percentLoaded;
            },
            function ( error ) {
                console.log( 'An error happened' );
            }
             
        );
        
        // adjustOnResize();
        var cint = 0;
        var Ang = 0;
        var targetAng = 0;
        var myReq;
        var previousClickedOBJName = "c1";
        const raycaster = new THREE.Raycaster();
        const pointer = new THREE.Vector2();
        var cam_deltaX ,cam_deltaY;
        var outFromPot = false;
        
        function onPointerDown( event ) {
            pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            pointer.y = - (event.clientY / window.innerHeight ) * 2 + 1;
            onPointerMove( event );
            
            var clickedOBJ = testClick3D();
            //alert(clickedOBJ.name);
            if (clickedOBJ == pointOB[0] || clickedOBJ == pointOB[1]) { 
                //alert ("คุณคลิก หนังสือ!");
                
               functionPointer = camera_gotoBook;
               book_loopcount = 0;
            } else if ( clickedOBJ == pointOB[2] || clickedOBJ == pointOB[4] ) {
                functionPointer = camera_gotoPot;
                pot_loopcount = 0;
                if(isReadyToMint){
                    //alert('dd');
                    //gotoMintPage();
                    //functionPointer = camera_gotoPot;
                }else{
                    
                }
                /*;*/
            } else if ( clickedOBJ == pointOB[3] ) {
                functionPointer = camera_gotoTV;
                TV_loopcount = 0;
            } else if ( clickedOBJ == pointOB[5] ) {
                functionPointer = camera_gotoPicture;
                picture_loopcount = 0;
            } else if (clickedOBJ.name.startsWith('magic')) {
                functionPointer = camera_gotoPartner;
                partner_loopcount = 0;
            } else if ( clickedOBJ == pointOB[6] 
                || clickedOBJ == pointOB[7]
                || clickedOBJ == pointOB[8]
                || clickedOBJ == pointOB[9]
                || clickedOBJ == pointOB[10] ) {
                functionPointer = camera_gotoNFT;
                NFT_loopcount = 0;
            } else {
                //isReadyToMint = false;
                setSpeedForNormal();
                functionPointer = camera_normal;
                normal_loopcount = 0;

                //action4.timeScale = -1;
                //action4.paused = false;
                //action4.play()
            }

            if(clickedOBJ.name.startsWith("MINT") ){
                if(isReadyToMint){
                    //gotoMintPage();
                    functionPointer = camera_gotoPot;
                    pot_loopcount = 0;
                }
            }

            if(clickedOBJ.name.startsWith("BALL_B1") ){
                gotoOpensea();
            }

            if(clickedOBJ.name.startsWith("BALL_B3") ){
                gotoOpenseaMasterpiece();
            }

            if(clickedOBJ.name.startsWith("BALL_B2") ){
                gotoAngel();
            }

            if(clickedOBJ.name.startsWith("TROPHY") ){
                gotoTrophy();
            }

        }

        function onPointerMove( event ) {
            turnHead_loopcount = 0;
            normal_loopcount = 0;
            turnHeadPointer = turnHead;
            pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            pointer.y = - (event.clientY / window.innerHeight ) * 2 + 1;
            var clickedOBJ = testClick3D();
            if(clickedOBJ){
                if(clickedOBJ.name == "pCube2_bookleather_0_1" ||
                clickedOBJ.name == "pCube2_bookleather_0"){
                    pointNO[0] = 1;
                    pointNO[1] = 1;
                } else if (clickedOBJ.name.startsWith("POT")){
                    pointNO[2] = 1; 
                    pointNO[12] = 0;
                } else if (clickedOBJ.name == "TV"){
                    pointNO[3] = 1; 
                } else if (clickedOBJ.name == "TEXT"){
                    pointNO[4] = 1; 
                } else if (clickedOBJ.name == "PICTURE"){
                    pointNO[5] = 1; 
                } else if (clickedOBJ.name == "Plane002_2" || 
                    clickedOBJ.name == "Plane002_3" || 
                    clickedOBJ.name == "Plane002_4" || 
                    clickedOBJ.name == "Plane002_5" || 
                    clickedOBJ.name == "Plane002_1"){
                    pointNO[6] = 1; 
                } else if (clickedOBJ.name.startsWith('magic')) {
                    obj1.getObjectByName("magic41")
                } else if (clickedOBJ.name.startsWith('BALL_B1')) {
                    pointNO[12] = 1;
                    pointNO[13] = 0;
                    pointNO[14] = 0;
                    pointNO[15] = 0;
                    pointNO[2] = 0;
                } else if (clickedOBJ.name.startsWith('BALL_B3')) {
                    pointNO[13] = 1;
                    pointNO[12] = 0;
                    pointNO[14] = 0;
                    pointNO[15] = 0;
                    pointNO[2] = 0;
                } else if (clickedOBJ.name.startsWith('BALL_B2')) {
                    pointNO[14] = 1;
                    pointNO[12] = 0;
                    pointNO[13] = 0;
                    pointNO[15] = 0;
                    pointNO[2] = 0;
                } else if (clickedOBJ.name.startsWith('TROPHY')) {
                    pointNO[15] = 1;
                    pointNO[12] = 0;
                    pointNO[13] = 0;
                    pointNO[14] = 0;
                    pointNO[2] = 0;
                } else{
                    pointNO[0] = 0;
                    pointNO[1] = 0;
                    pointNO[2] = 0;
                    pointNO[3] = 0;
                    pointNO[4] = 0;
                    pointNO[5] = 0;
                    pointNO[6] = 0;
                    pointNO[7] = 0;
                    pointNO[8] = 0;
                    pointNO[9] = 0;
                    pointNO[10] = 0;
                    pointNO[11] = 0;
                    pointNO[12] = 0;
                    pointNO[13] = 0;
                    pointNO[14] = 0;
                    pointNO[15] = 0;
                    //obj1.traverse((node)=>{});
                }
            }
            //document.getElementById('stat1').innerHTML = "<center>" + (pointer.y) + "</center>";
        }
        
        
        //**********************************************************
        var camY , camX , camZ , camRY , camRX, camRZ , focusTarget , speedCamX , speedCamY , offsetY;
        function setSpeedForNormal(){
            if(window.innerWidth < window.innerHeight){
                speedCamX = 1.3;
                speedCamY = .5;
                offsetY = .7;
                camZ = 1;
            }else{
                speedCamX = .3;
                speedCamY = .3;
                offsetY = .6;
                camZ = .3;
            }
        }
        setSpeedForNormal();
        function camera_normal() {
            if(normal_loopcount <= 100){
                camX = pointer.x * speedCamX;
                camY = offsetY + ( pointer.y * speedCamY );
                //camZ = .3 ;
                camRX = 0;
                camRY = 0;
                camRZ = 0;
                focusTarget = 2.95;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 + extraZ);
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                normal_loopcount += 1;
            }else{
                //functionPointer = emptyFunction;
            }
        }
        /******************************************************/
        function camera_gotoNFT(){
            if(NFT_loopcount <= 100) {
                camX = -.9;
                camY = 1.35;
                camZ = -2.2;
                camRX = 0;
                camRY = .5;
                camRZ = 0;
                focusTarget = .8;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 );
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                book_loopcount += 1;
                if(book_loopcount == 80){
                    //showPaper();
                }
            }else{
                functionPointer = emptyFunction;
            }
        }

        function showNFT(){
            //document.getElementById("display_div").style.display = 'flex';
            //document.getElementById("paper_div").style.display = 'flex';
            //window.removeEventListener('pointerdown', onPointerDown);
            //document.getElementById("top_menu").style.display = 'none';
        }
        function closeNFT(){
            //functionPointer = camera_normal;
            //normal_loopcount = 0;
            //document.getElementById("paper_div").style.display = 'none';
            //document.getElementById("display_div").style.display = 'none';
            //window.addEventListener('pointerdown', onPointerDown);
            //document.getElementById("top_menu").style.display = 'block';
        }
        closeNFTPointer = closeNFT;

        /******************************************************/
        function camera_gotoBook(){
            if(book_loopcount <= 100) {
                camX = -.8;
                camY = .25;
                camZ = -.6;
                camRX = -.1;
                camRY = 0;
                camRZ = 0;
                focusTarget = 1;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 );
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                book_loopcount += 1;
                if(book_loopcount == 50){
                    showPaper();
                }
            }else{
                functionPointer = emptyFunction;
            }
        }
        function showPaper(){
            document.getElementById("display_div").style.display = 'flex';
            document.getElementById("paper_div").style.display = 'flex';
            window.removeEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'none';
        }
        function closePaper(){
            setSpeedForNormal();
            functionPointer = camera_normal;
            normal_loopcount = 0;
            document.getElementById("paper_div").style.display = 'none';
            document.getElementById("display_div").style.display = 'none';
            window.addEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'block';
        }
        closePaperPointer = closePaper;

        /******************************************************/

        function camera_gotoPartner(){
            if(partner_loopcount <= 100) {
                camX = .025;
                camY = .36;
                camZ = -1.97;
                camRX = 0;
                camRY = 0;
                camRZ = 0;
                focusTarget = 1;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 );
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                //bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                partner_loopcount += 1;
                if(partner_loopcount == 30){
                    showPartner();
                }
            }else{
                functionPointer = emptyFunction;
            }
        }
        function showPartner(){
            document.getElementById("display_div").style.display = 'block';
            document.getElementById("partner_div").style.display = 'flex';
            setTimeout(()=>{
                document.getElementById("partner_img").style.width = '80%';
            },100);
            window.removeEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'none';
        }
        function closePartner(){
            setSpeedForNormal();
            functionPointer = camera_normal;
            normal_loopcount = 0;
            document.getElementById("display_div").style.display = 'none';
            document.getElementById("partner_div").style.display = 'none';
            document.getElementById("partner_img").style.width = '0%';
            window.addEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'block';
        }
        closePartnerPointer = closePartner;

        /******************************************************/

        function camera_gotoTV(){
            if(TV_loopcount <= 100) {
                camX = -.8;
                camY = .93;
                camZ = -2.2;
                camRX = 0;
                camRY = .55;
                camRZ = 0;
                focusTarget = 1;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 );
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                TV_loopcount += 1;
                if(TV_loopcount == 80){
                    showVDO();
                }
            }else{
                functionPointer = emptyFunction;
            }
        }

        function showVDO(){
            document.getElementById("display_div").style.display = 'block';
            document.getElementById("vdo_div").style.display = 'flex';
            window.removeEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'none';
        }

        function closeVDO(){
            setSpeedForNormal();
            functionPointer = camera_normal;
            normal_loopcount = 0;
            document.getElementById("vdo_div").style.display = 'none';
            document.getElementById("display_div").style.display = 'none';
            window.addEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'block';
        }

        closeVDOPointer = closeVDO;

        /******************************************************/
        function camera_gotoPot() {
            
            if(pot_loopcount <= 100){
                camX = .5;
                camY = .45;
                camZ = -.3;
                camRX = 0;
                camRY = -.3;
                camRZ = 0;
                focusTarget = 1.6;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 );
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                pot_loopcount += 1;
                if(pot_loopcount == 70){
                    gotoMintPage();
                    //action4.timeScale = 1;
                   // action4.paused = false;
                   // action4.play()
                }
                //console.log(pot_loopcount);
            }else{
                //action3.clampWhenFinished = false;
                outFromPot = true;
                functionPointer = emptyFunction;
            }
        }
        /******************************************************/
        function camera_gotoPicture() {
            if(picture_loopcount <= 100){
                camX = .75;
                camY = 1.35;
                camZ = -1.5;
                camRX = 0;
                camRY = -.1;
                camRZ = 0;
                focusTarget = 1.6;
                camera.position.set( camera.position.x + ( camX - camera.position.x)/20 ,
                                    camera.position.y + ( camY - camera.position.y)/20 , 
                                    camera.position.z + ( camZ - camera.position.z)/20 );
                camera.rotation.set( camera.rotation.x + ( camRX - camera.rotation.x)/20 ,
                                    camera.rotation.y + ( camRY - camera.rotation.y)/20 , 
                                    camera.rotation.z + ( camRZ - camera.rotation.z)/20 );
                bokehPass.uniforms[ 'focus' ].value = bokehPass.uniforms[ 'focus' ].value + (focusTarget - bokehPass.uniforms[ 'focus' ].value)/10;
                picture_loopcount += 1;
                if(picture_loopcount == 50){
                    showFounder();
                }
            }else{
                functionPointer = emptyFunction;
            }
        }
        function showFounder(){
            document.getElementById("display_div").style.display = 'block';
            document.getElementById("founder_div").style.display = 'flex';
            setTimeout(()=>{
                document.getElementById("founder_img").style.width = '80%';
            },100);
            window.removeEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'none';
        }
        function closeFounder(){
            setSpeedForNormal();
            functionPointer = camera_normal;
            normal_loopcount = 0;
            document.getElementById("display_div").style.display = 'none';
            document.getElementById("founder_div").style.display = 'none';
            document.getElementById("founder_img").style.width = '0%';
            window.addEventListener('pointerdown', onPointerDown);
            document.getElementById("top_menu").style.display = 'block';
        }
        closeFounderPointer = closeFounder;
        //**********************************************************

        //window.addEventListener('scroll',onScrollRoadmap1);
        function testClick3D() {
            //camera.rotation.x = .01;
            raycaster.setFromCamera( pointer, camera );
            const intersects = raycaster.intersectObjects( scene.children );
            if(intersects.length){
                //intersects[ 0 ].object.material.color.set( 0xFF0000 );
                return intersects[ 0 ].object;
            }else{
                return null;
            }
        }
        //renderer.setPixelRatio(window.devicePixelRatio);
        function turnHead(){
            if(turnHead_loopcount <= 100) {
                obj1.getObjectByName("Head").rotation.y = obj1.getObjectByName("Head").rotation.y + (pointer.x/2 - obj1.getObjectByName("Head").rotation.y ) / 10;
                obj1.getObjectByName("Head").rotation.x = obj1.getObjectByName("Head").rotation.x + (-pointer.y/5 - obj1.getObjectByName("Head").rotation.x ) / 10;
                obj1.getObjectByName("L1").position.x = obj1.getObjectByName("L1").position.x + (2*pointer.x - obj1.getObjectByName("L1").position.x ) / 10;
                obj1.getObjectByName("L1").position.y = obj1.getObjectByName("L1").position.y + ((pointer.y/10 + 1.1) - obj1.getObjectByName("L1").position.y ) / 10;
                turnHead_loopcount += 1;
            }else{
                turnHeadPointer = emptyFunction;
            }
        }
        //**************************** UPDATE FONT ****************************
        function updateText(fontL,text){
            if(textMesh){
                obj1.remove(textMesh);
            }
            text_geometry = new TextGeometry(text,{
                font: fontL ,
                size : .09 ,
                height : .02 
            });
            //text_geometry.computeTangents();
            textMesh = new THREE.Mesh(text_geometry,[
                new THREE.MeshPhongMaterial({color:0xFFFFFF}),
                new THREE.MeshPhongMaterial({color:0x222222})
            ]);
            //textMesh.material.side = THREE.DoubleSide;
            textMesh.castShadow = false;
            textMesh.position.set(.6,.705,-1.9);
            textMesh.rotation.set(0,-.2,0);
            textMesh.material = obj1.getObjectByName("METAL_PLANE").material;
            obj1.add(textMesh);
            textMesh.name = 'TEXT';
            textMesh.castShadow = true;
            textMesh.receiveShadow = false;
            pointOB = [ obj1.getObjectByName("pCube2_bookleather_0_1") ,
                obj1.getObjectByName("pCube2_bookleather_0") ,
                obj1.getObjectByName("POT_1") ,
                obj1.getObjectByName("TV") ,
                textMesh , 
                obj1.getObjectByName("PICTURE") ,
                obj1.getObjectByName("Plane002_1") ,
                obj1.getObjectByName("Plane002_2") ,
                obj1.getObjectByName("Plane002_3") ,
                obj1.getObjectByName("Plane002_4") ,
                obj1.getObjectByName("Plane002_5")  ];
        }

        const getCountDown = () => {
            const today = new Date();
            //const presaleDate = new Date((1661800)*1000); 
            const presaleDate = new Date("September 1, 2022 20:00:00 GMT+07:00");
            //TimeZone.setDefault( TimeZone.getTimeZone("UTC"));
            //alert(presaleDate.toUTCString());
            difftime = presaleDate.getTime() - today.getTime();
            if(difftime < 0) {
              difftime = 0;
              //clearInterval(interv5);
              //document.getElementById('countdownpart').style.display = 'none';  
              isReadyToMint = true;
            }

            diffDay = Math.floor(difftime/3600/1000/24);
            diffHour = Math.floor(difftime/3600/1000);
            diffMinute = Math.ceil(difftime/60/1000) - diffHour*60 - 1;
            diffSecond = parseInt(difftime/1000)%60;
            diffHour24 = parseInt(difftime/3600/1000) % 24;
            if(diffMinute < 0) diffMinute=0;

            var t1 = (diffDay < 10 ? '0':'') + diffDay;
            var t2 = (diffHour24 < 10 ? '0':'') + diffHour24;
            var t3 = (diffMinute < 10 ? '0':'') + diffMinute;
            var t4 = (diffSecond < 10 ? '0':'') + diffSecond;
            timeText = t1 + ":" + t2 + ":" + t3 + ":" + t4;
        };
        
        //***********************************************************
        composer.setSize(w, h);
        const renderPass = new RenderPass( scene, camera );
        composer.addPass( renderPass );
        const bokehPass = new BokehPass( scene, camera, {
            focus: 5,
            aperture: 0.002,
            maxblur: .5,
        } );
        //alert(bokehPass.resolution);
        const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight), .5, .5, .4);
        const effectFXAA = new ShaderPass( FXAAShader );
        var pixelRatio = renderer.getPixelRatio();
        effectFXAA.uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio );
        effectFXAA.uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio );
        composer.addPass(effectFXAA);
        composer.addPass( bloomPass );
        composer.addPass( new ShaderPass( GammaCorrectionShader ) );
        
        if(window.innerWidth >= 1100){
            //composer.addPass( bokehPass ); 
        }

        function emptyFunction(){}
        /************************ ANIMATE FUNCTION  ******************/
        cint = 0;
        var currentPoint = new THREE.Vector2(0,0);
        var functionPointer = camera_normal;
        var turnHeadPointer = turnHead;
        var animate = function () {
            //renderer.setClearColor(0x, 1);
            myReq = requestAnimationFrame( animate );
            functionPointer();
            turnHeadPointer();
            var i = 0;
            pointOB.forEach(e1 => {
                
                if(pointNO[i] == 1){
                    try {
                        e1.material.emissiveIntensity = e1.material.emissiveIntensity + (400 - e1.material.emissiveIntensity)/10;
                    } catch{}
                } else {
                    try {
                        e1.material.emissiveIntensity = e1.material.emissiveIntensity + (pointEM1[i] - e1.material.emissiveIntensity)/4;
                    }catch{}
                }
                i=i+1;
            });
            if(obj1) {
                //particle5.material.opacity = .5 * (Math.cos(.5 * .0174532926 * (cint+40)) + 1) ;
            }
            var delta = clock.getDelta();
			if ( mixer1 ) mixer1.update( delta );
            composer.render();
            //renderer.render(scene, camera); 

        }; 
        //******************** AUDIO **********************
        playSound = () => {
            const listener = new THREE.AudioListener();
            audio = new THREE.Audio( listener );
            const file = 'bg3.mp3';
            const loader = new THREE.AudioLoader();
            loader.load( file, function ( buffer ) {
                audio.setBuffer( buffer );
                audio.setLoop( true );
	            audio.setVolume( 0.5 );
                audio.play();
            } ); 
            /*if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) {
                const loader = new THREE.AudioLoader();
                loader.load( file, function ( buffer ) {
                    audio.setBuffer( buffer );
                    audio.setLoop( true );
	                audio.setVolume( 0.5 );
                    audio.play();
                } );
            } else {
                const mediaElement = new Audio( file );
                mediaElement.play();
                audio.setMediaElementSource( mediaElement );
            }*/
        }
        sceneOpening = ()=> {
            document.getElementById("start_div").style.display = 'none';
            window.addEventListener('pointermove', onPointerMove);
            //document.getElementById("sample-layer2-part2-sub1").addEventListener('pointermove', onPointerMove);
            document.getElementById("sample-layer2-part2-sub1").addEventListener('pointerdown', onPointerDown);
            playSound();
        }
    }

    beginJourney = () => {
        document.getElementById('top_menu').style.display = 'block';
        document.getElementById("start_div").style.filter = 'opacity(0%)';
        
        document.getElementById("sample-layer2-part2-sub1").style.filter = 'saturate(1.35)';
        if(window.innerHeight > window.innerWidth){
            document.getElementById("zoom_div").style.display = 'none';
        }else{
            document.getElementById("zoom_div").style.display = 'block';
        }
        setTimeout(sceneOpening,2000);
    }

    zoomIN = () => {
        extraZ = extraZ + .03;
        if(extraZ >= .06) extraZ = .06
        normal_loopcount = 0;
    }
    zoomOUT = () => {
        extraZ = extraZ - .03;
        if(extraZ <= -.06) extraZ = -.06
        normal_loopcount = 0;
    }
    
    offSound = () =>{
        document.getElementById('bgm_off').style.display = 'inline-block';
        document.getElementById('bgm_on').style.display = 'none';
        audio.stop();
    }
    onSound = () =>{
        document.getElementById('bgm_off').style.display = 'none';
        document.getElementById('bgm_on').style.display = 'inline-block';
        audio.play();
    }
    componentWillUnmount() {
        document.getElementById("sample-layer2-part2-sub1").removeEventListener('pointermove', this.onPointerMove);
        document.getElementById("sample-layer2-part2-sub1").removeEventListener('pointerdown', this.onPointerDown);
        document.getElementById("sample-layer2-part2-sub1").removeEventListener('scroll',this.onScrollRoadmap1);
        document.getElementById("sample-layer2-part2-sub1").removeEventListener('scroll',this.onScrollRoadmap2);
    }

    render(){
        return (
            <div className="roadmap-maindiv" id="roadmap-maindiv">
                
                <div className="header_menu" id="top_menu" style={{display:'none'}}><Header2 /></div>
                <div id="start_div" >
                    <div id="start_div_2">
                        <center>
                            <label id="pgbar_label" for="pgbar"> LOADING...</label><br/><br/>
                            <progress id="pgbar" value="32" max="100" style={{width:'300px'}}> 32% </progress>
                            <button id='button_to_begin' onClick={()=>{this.beginJourney()}}> Begin The Journey </button>
                        </center>
                    </div>
                </div>
                <div id='sample-layer2-part2-sub1' ref={ref => (this.mount = ref)}></div>
                <div id="zoom_div">
                    <img id="bgm_on" src = "bgm_on.png" onClick={this.offSound}></img>
                    <img id="bgm_off" src = "bgm_off.png" onClick={this.onSound} style={{display:'none'}}></img>
                    <img id="zi_icon" src = "zoom_in_icon.png" onClick={this.zoomOUT}></img>
                    <img id="zo_icon" src = "zoom_out_icon.png" onClick={this.zoomIN}></img>
                </div>
                <div id="display_div" >
                    
                    <div id="vdo_div" >
                    <div className="closeBTN"><img src="close_white.png" width="40" height="40" onClick={()=>{closeVDOPointer();} }></img></div>
                        <div>
                            
                            <iframe width="660" height="400" src="https://www.youtube.com/embed/zR-uTu_MBgI?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <iframe width="660" height="400" src="https://www.youtube.com/embed/yhYmOKB5CIE?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> <br/>
                            <iframe width="660" height="400" src="https://www.youtube.com/embed/ToHdYk4GOh8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <iframe width="660" height="400" src="https://www.youtube.com/embed/4a4RF2vvrM8?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div id="founder_div" >
                        <div className="closeBTN"><img src="close_white.png" width="40" height="40" onClick={()=>{closeFounderPointer();} }></img></div>
                        <img id="founder_img" src="Founder_All.jpg" ></img>
                    </div>
                    <div id="partner_div" >
                        <div className="closeBTN"><img src="close_black.png" width="40" height="40" onClick={()=>{closePartnerPointer();} }></img></div>
                        <img id="partner_img" src="Partner_All.png?update=5" ></img>
                    </div>
                    <div id="paper_div" >
                        <div className="closeBTN"><img src="close_white.png" width="40" height="40" onClick={()=>{closePaperPointer();} }></img></div>
                        <iframe src={`https://www.bittoondao.com/litepaper/?po5="${ranN}`} style={{width:'90%',height:'90%'}}></iframe>
                    </div>
                </div>    
            </div>
        )
    }
}
export default Roadmap;

function gotoMintPage(){  
}
function gotoMintPage_back(){
    var win;
    if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) {
        win = window.open('/mint', '_self');
    } else {
        win = window.open('/mint', '_blank');
    }
    if (win != null) { win.focus(); }      
}

function gotoOpensea(){
    var win;
    if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) {
        win = window.open('https://opensea.io/collection/bittoon-dao', '_self');
    } else {
        win = window.open('https://opensea.io/collection/bittoon-dao', '_blank');
    }
    if (win != null) { win.focus(); }      
}

function gotoAngel(){
    var win;
    if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) {
        win = window.open('https://opensea.io/collection/bittoon-dao-angels', '_self');
    } else {
        win = window.open('https://opensea.io/collection/bittoon-dao-angels', '_blank');
    }
    if (win != null) { win.focus(); }      
}

function gotoTrophy(){
    var win;
    if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) {
        win = window.open('https://opensea.io/collection/bittoondao-trophy-collection', '_self');
    } else {
        win = window.open('https://opensea.io/collection/bittoondao-trophy-collection', '_blank');
    }
    if (win != null) { win.focus(); }      
}

function gotoOpenseaMasterpiece(){
    var win;
    if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) {
        win = window.open('https://opensea.io/collection/bittoon-dao-masterpiece', '_self');
    } else {
        win = window.open('https://opensea.io/collection/bittoon-dao-masterpiece', '_blank');
    }
    if (win != null) { win.focus(); }      
}